<template>
  <div>
    <!-- 操作按钮区域 -->
    <div class="margin-b-15">
      <a-button @click="handleAdd"
                type="primary"
                icon="plus-square">新增</a-button>
      <a-button v-if="selectedRowKeys.length > 0"
                type="danger"
                ghost
                icon="delete"
                @click="batchDel">批量删除</a-button>
      <a-switch checkedChildren="是"
                unCheckedChildren="否"
                :defaultChecked="false"
                @click="isSwitch" />
    </div>
    <!-- table区域-begin -->
    <div class="ant-alert ant-alert-info"
         style="margin-bottom: 16px;">
      <i class="anticon anticon-info-circle ant-alert-icon"></i>已选择&nbsp;
      <a style="font-weight: 600">{{ selectedRowKeys.length }}</a>&nbsp;项&nbsp;&nbsp;
      <a class="margin-l-8"
         @click="onClearSelected">清空</a>
    </div>
    <div v-if="dataSource && dataSource.length">
      <a-table :columns="columns"
               size="middle"
               :pagination="false"
               @expand="expandSubmenu"
               :dataSource="dataSource"
               :loading="loading"
               :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
               rowKey="id"
               :defaultExpandAllRows="isNo">
        <span slot="action"
              slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link">
              更多
              <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;"
                   @click="handleDetail(record)">详情</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;"
                   @click="handleAddSub(record)">添加子菜单</a>
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm title="确定删除吗?"
                              @confirm="() => handleDelete(record.id)">
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
    </div>
    <!-- table区域-end -->
    <PermissionModal ref="modalForm"
                      @ok="modalFormOk"></PermissionModal>
  </div>
</template>

<script>
import PermissionModal from './permitssionTop-model.vue'
import api from './api'

const columns = [
  {
    title: '菜单名称',
    dataIndex: 'name',
    key: 'name'
  }, {
    title: '菜单类型',
    dataIndex: 'menuType',
    key: 'menuType',
    customRender: function (text) {
      if (text === 0) {
        return '菜单'
      } else if (text === 1) {
        return '菜单'
      } else if (text === 2) {
        return '按钮/权限'
      } else {
        return text
      }
    }
  }, /* {
    title: '权限编码',
    dataIndex: 'perms',
    key: 'permissionCode',
  }, */{
    title: 'icon',
    dataIndex: 'icon',
    key: 'icon'
  },
  {
    title: '组件',
    dataIndex: 'component',
    key: 'component',
    scopedSlots: { customRender: 'component' }
  },
  {
    title: '路径',
    dataIndex: 'url',
    key: 'url',
    scopedSlots: { customRender: 'url' }
  },
  {
    title: '排序',
    dataIndex: 'sortNo',
    key: 'sortNo'
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    width: 150
  }
]

export default {
  props: {
    tabKey: {
      type: String
    }
  },
  type: {},
  name: 'PermissionTop',
  components: {
    PermissionModal
  },
  data () {
    return {
      isNo: false,
      selectedRowKeys: [],
      dataSource: [],
      report: {
      },
      description: '这是菜单管理页面',
      // 表头
      columns: columns,
      loading: false
    }
  },
  methods: {
    loadData () {
      this.dataSource = []
      this.type = { type: '2' }
      if (this.tabKey === '1') {
        this.type = { type: '1' }
      } else if (this.tabKey === '2') {
        this.type = { type: '2' }
      }
      api.getMenuTree().then((res) => {
        this.dataSource = res.data
      })
    },
    expandSubmenu (expanded, record) {
      if (expanded && (!record.children || record.children.length === 0)) {
        api.getSystemSubmenu({ parentId: record.id }).then((res) => {
          if (res.success) {
            record.children = res.data
          }
        })
      }
    },
    isSwitch (checked, event) {
      if (checked === true) {
        this.isNo = true
        this.loadData()
      } else {
        this.isNo = false
        this.loadData()
      }
    },
    /* // 打开数据规则编辑
    handleDataRule (record) {
      this.$refs.PermissionDataRuleList.edit(record)
    }, */
    handleAddSub (record) {
      this.$refs.modalForm.title = '添加子菜单'
      this.$refs.modalForm.localMenuType = 1
      this.$refs.modalForm.disableSubmit = false
      this.$refs.modalForm.edit({ permsType: 1, route: 1, hideInMenu: 0, alwaysShow: 0, keepAlive: 1, parentId: record.id, menuGroup: this.tabKey })
      this.$refs.modalForm.XqDisabled = false
    },
    // 新增
    handleAdd: function () {
      this.$refs.modalForm.add()
      this.$refs.modalForm.title = '新增'
      this.$refs.modalForm.showSubmit = true
      this.$refs.modalForm.disableSubmit = false
      this.$refs.modalForm.XqDisabled = false
    },
    // 编辑
    handleEdit: function (record) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '编辑'
      this.$refs.modalForm.showSubmit = true
      this.$refs.modalForm.disableSubmit = false
      this.$refs.modalForm.XqDisabled = false
    },
    // 详情
    handleDetail: function (record) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '详情'
      this.$refs.modalForm.showSubmit = false
      this.$refs.modalForm.disableSubmit = true
      this.$refs.modalForm.XqDisabled = true
    },
    // 多选删除
    batchDel: function () {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请选择一条记录！')
      } else {
        var ids = ''
        for (var a = 0; a < this.selectedRowKeys.length; a++) {
          ids += this.selectedRowKeys[a] + ','
        }
        var that = this
        this.$confirm({
          title: '确认删除',
          content: '是否删除选中数据?',
          cancelText: '取消',
          okText: '确定',
          onOk: function () {
            api.deleteBatch({ ids: ids }).then((res) => {
              that.$message.success(res.message)
              that.loadData()
              that.onClearSelected()
            })
          }
        })
      }
    },
    // 单项删除
    handleDelete: function (id) {
      var that = this
      api.delPermission({ id: id }).then((res) => {
        that.$message.success(res.message)
        that.loadData()
      })
    },
    onClearSelected () {
      this.selectedRowKeys = []
    },
    modalFormOk () {
      // 新增/修改 成功时，重载列表
      this.loadData()
    },
    onSelectChange (selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  },
  created () {
    this.loadData()
  }
}
</script>
